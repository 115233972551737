<template>
  <v-container class="payment-auth generic-list">
    <CategoryTitle :category="category" :add-container="false" />
    <div v-if="!isEmpty">
      <v-row class="headers mt-5">
        <v-col cols="4">{{ $t("payment.paymentMethods.circuit") }}</v-col
        ><v-col cols="4" sm="5">{{ $t("payment.paymentMethods.card") }}</v-col>
        <v-col cols="2">{{ $t("payment.paymentMethods.expiration") }}</v-col>
        <v-col cols="2" sm="1"></v-col>
      </v-row>
      <div v-for="(payment, index) in paymentAuthList" :key="index">
        <div v-if="payment.length > 0">
          <div class="paymentAuth">
            <v-row
              align="center"
              v-for="paymentAuth in payment"
              :key="'pa_' + paymentAuth.userPaymentAuthId"
            >
              <v-col cols="4"
                ><div class="d-flex flex-column flex-sm-row align-center">
                  <img
                    v-if="paymentAuth.creditCard"
                    :src="getImgUrl(paymentAuth.creditCard)"
                    alt="card"
                    height="50"
                    class="mr-2"
                  />
                  <div class="text-capitalize">
                    {{ paymentAuth.creditCard | cctype }}
                  </div>
                </div></v-col
              ><v-col cols="4" sm="5" v-if="paymentAuth.creditCard">{{
                paymentAuth.creditCard | ccard
              }}</v-col>
              <v-col cols="2">{{ paymentAuth.expireDate | ccexp }}</v-col>
              <v-col cols="2" sm="1" class="d-flex justify-end"
                ><v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      width="40"
                      min-width="40"
                      @click.stop.prevent="
                        deletePayment(paymentAuth.userPaymentAuthId)
                      "
                    >
                      <v-icon>$delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("payment.paymentMethods.removePaymentMethod")
                  }}</span>
                </v-tooltip></v-col
              >
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!loading">
      {{ $t("payment.paymentMethods.noPaymentMethods") }}
    </div>
  </v-container>
</template>
<style lang="scss">
.payment-auth {
  .paymentAuth {
    .row {
      background-color: #fff;
      border: 1px solid var(--v-grey-lighten1);
      border-radius: 8px;
      margin-bottom: 5px;
    }
    .row:hover {
      background-color: var(--v-grey-lighten1);
    }
  }
  .headers {
    font-weight: 600;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeService from "~/service/paymentTypeService";

import categoryMixins from "~/mixins/category";

export default {
  name: "MyPaymentMethods",
  mixins: [categoryMixins],
  components: {
    CategoryTitle
  },
  data() {
    return {
      loading: true,
      config: null,
      paymentList: [],
      paymentAuthList: [],
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: false,
        hidePrevButton: true,
        showRememberCard: false,
        showAddNewCardWarning: false
      }
    };
  },
  computed: {
    isEmpty() {
      if (this.paymentAuthList.length === 0) {
        return true;
      }

      for (const array of this.paymentAuthList) {
        if (array.length > 0) {
          return false;
        }
      }

      return true;
    }
  },
  methods: {
    async fetchPaymentTypeList() {
      try {
        this.loading = true;
        PaymentTypeService.getPaymentTypeList(this.orderId, true).then(data => {
          this.paymentList = data;
          this.fetchPaymentsAuth();
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async fetchPaymentsAuth() {
      this.paymentList.forEach(async payment => {
        await PaymentTypeService.getPaymentsAuth(payment.paymentTypeId).then(
          paymentData => {
            this.paymentAuthList.push(paymentData);
            payment.paymentAuthList = paymentData;
          }
        );
      });
    },
    getCardType(creditCard) {
      return this.$options.filters.cctype(creditCard);
    },
    getImgUrl(creditCard) {
      const cctype = this.getCardType(creditCard);
      return `/credit-card/${cctype}-curved-64px.png`;
    },
    async deletePayment(paymentAuthId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("payment.paymentMethods.confirmRemovePaymentMethod")
      });
      if (res) {
        PaymentTypeService.deletePaymentAuth(paymentAuthId).then(() => {
          this.fetchPaymentTypeList();
        });
      }
    }
  },
  async created() {
    this.config = global.config;
    await this.fetchPaymentTypeList();
  }
};
</script>
